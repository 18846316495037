import storage from "local-storage-fallback";
import momentTimezone from "moment-timezone";
import Moment from "moment";
import { extendMoment } from "moment-range";

import paymentMethods from "../enum/paymentMethods";
import channels from "../enum/channels";

import amex from "../images/ccflags/amex.svg";
import elo from "../images/ccflags/elo.svg";
import hipercard from "../images/ccflags/hipercard.svg";
import mastercard from "../images/ccflags/mastercard.svg";
import visa from "../images/ccflags/visa.svg";

import amexBrand from "../images/brands/brand_amex.svg";
import eloBrand from "../images/brands/brand_elo.svg";
import hipercardBrand from "../images/brands/brand_hipercard.svg";
import mastercardBrand from "../images/brands/brand_mastercard.svg";
import visaBrand from "../images/brands/brand_visa.svg";

import eloBrandMc from "../images/brands/mc_elo.svg";
import hipercardBrandMc from "../images/brands/mc_hipercard.svg";
import mastercardBrandMc from "../images/brands/mc_mastercard.svg";
import visaBrandMc from "../images/brands/mc_visa.svg";

import defaultCard from "../images/card_red.svg";
import frequencyEnum from "../enum/rechargeFrequency";
import { PROPS_UPSELL } from "../constants/rechargeUpsell";
import typeUpsell from "../enum/typeUpsell";
import paymentRoutes from "../enum/paymentSuccessRoutes";
import { LIMIT_DATE_WILYKIT } from "../constants/valuesToScheduledRecharge";
import segments from "../enum/segments";

const getCardFlag = (flags, cardNumber) => {
  let result;
  let flag;
  let subCardNumber;

  const filteredCardFlag = data => {
    const newData = data.replace("%", "");
    subCardNumber = cardNumber
      .replace("_", "")
      .substring(0, newData.length)
      .replace(/[^0-9]+/g, "");

    if (subCardNumber.includes(newData)) {
      result = flag;
    }

    return result;
  };

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (flag in flags) {
    flags[flag].filter(filteredCardFlag);
  }

  return result ? result.toString().toLowerCase() : "default";
};

const getCardFlagImage = flag =>
  ({
    amex,
    elo,
    hipercard,
    mastercard,
    visa,
  }[flag]);

const getCardBrandImage = (flag, isMC = false) => {
  return flag
    ? {
        amexBrand,
        eloBrand: isMC ? eloBrandMc : eloBrand,
        hipercardBrand: isMC ? hipercardBrandMc : hipercardBrand,
        mastercardBrand: isMC ? mastercardBrandMc : mastercardBrand,
        visaBrand: isMC ? visaBrandMc : visaBrand,
      }[`${flag.toLowerCase()}Brand`] || defaultCard
    : defaultCard;
};

const msisdnFormatter = msisdn => {
  if (!msisdn) {
    return "";
  }

  return msisdn.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3");
};

// eslint-disable-next-line no-restricted-globals
const isValidMsisdn = msisdn => msisdn && msisdn.length === 11 && !isNaN(msisdn);

const cpfFormatter = cpf => cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

const dateOnlyFormatter = date => {
  if (!date || date === "") {
    return null;
  }

  const d = date.split("-");
  return `${d[2]}/${d[1]}/${d[0]}`;
};

const moneyFormatter2 = value => `${(value / 100).toString().replace(".", ",")}`;

const installmentFormatter = value =>
  `R$ ${(value / 100)
    .toFixed(2)
    .toString()
    .replace(".", ",")}`;

const categoryFormatter = value => value.replace("_", " ");

const priceFormatter = value => `R$${value / 100}`;

const filteredRecharges = (rechargeHistory, selectedMonthYear, selectedMsisdn) => {
  const monthYear = `${selectedMonthYear.split("-")[1]}/${selectedMonthYear.split("-")[0]}`;
  const filtered = rechargeHistory.filter(recharge => {
    const rechargeMonthYear = `${recharge.registerDate.split("-")[1]}/${
      recharge.registerDate.split("-")[0]
    }`;
    return rechargeMonthYear === monthYear && recharge.targetMsisdn === selectedMsisdn;
  });
  return filtered;
};

const splitMoney = value => {
  const result = { number: null, decimal: null };

  if (value === null || value === undefined) {
    return result;
  }

  try {
    const formattedNumber = parseFloat(value / 100).toFixed(2);
    result.number = formattedNumber.substr(0, formattedNumber.length - 3);
    result.decimal = formattedNumber.substr(-2);
  } catch (e) {
    result.number = null;
    result.decimal = null;
  }

  return result;
};

const normalizeRechargeValue = rechargeValue => {
  const { data, ...updatedRechargeValue } = rechargeValue;

  return updatedRechargeValue;
};

const normalizePaymentMethod = (paymentMethod, cvv) => {
  const updatedData = { ...paymentMethod.data, cvv };
  delete updatedData.data3ds;

  return {
    ...paymentMethod,
    data: updatedData,
    authentication: {
      ...(paymentMethod.data.data3ds || {}),
    },
  };
};

const normalizeGooglePay = paymentData => {
  const { cardNetwork, cardDetails } = paymentData.paymentMethodData.info;
  const { description } = paymentData.paymentMethodData;
  const { token } = paymentData.paymentMethodData.tokenizationData;

  return {
    type: paymentMethods.GOOGLE_PAY,
    data: {
      card_token: token,
      card_details: cardDetails,
      card_description: description,
      card_network: cardNetwork,
    },
  };
};

const generatePayload = (newRecharge, cvv) => {
  const {
    rechargeValue,
    paymentMethod,
    day,
    targetMsisdn,
    name,
    frequency,
    isScheduled,
  } = newRecharge;

  if (isScheduled) {
    return {
      targetMsisdn,
      name,
      day,
      frequency,
      cvv,
      rechargeValue: rechargeValue.value,
      token: paymentMethod.data.token,
      type: paymentMethods.CREDITO,
      productId: rechargeValue.id,
    };
  }

  return {
    ...newRecharge,
    rechargeValue: normalizeRechargeValue(rechargeValue),
    paymentMethod: cvv ? normalizePaymentMethod(paymentMethod, cvv) : paymentMethod,
  };
};

const inPeriod = (begin, finish) => {
  momentTimezone.tz.setDefault("America/Sao_Paulo");
  const now = momentTimezone();
  const start = momentTimezone(begin);
  const end = momentTimezone(finish);
  const moment = extendMoment(Moment);
  const range = moment().range(start, end);

  return range.contains(now);
};

const isChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");

  return !!(
    isIOSChrome ||
    (isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false)
  );
};

const cvvMask = () => (`${storage.getItem("brandName")}`.toLowerCase() === "amex" ? "9999" : "999");

const cpfMask = cpf => {
  const r = cpf.replace(/\D/g, "");

  if (r.length === 0) {
    return "";
  }

  if (r.length <= 3) {
    return r;
  }

  let regex = /^(\d{3})/;
  let subst = "$1.";

  if (r.length > 9) {
    regex = /^(\d{3})(\d{3})(\d{3})(\d{0,2})/;
    subst = "$1.$2.$3-$4";
  } else if (r.length > 6) {
    regex = /^(\d{3})(\d{3})/;
    subst = "$1.$2.";
  }

  return r.replace(regex, subst);
};

const msisdnMask = msisdn => {
  const r = msisdn.replace(/\D/g, "");

  if (r.length === 0) {
    return "";
  }

  let regex = /^(\d*)/;
  let subst = "($1";

  if (r.length > 7) {
    regex = /^(\d\d)(\d{5})(\d{0,4}).*/;
    subst = "($1) $2-$3";
  } else if (r.length > 2) {
    regex = /^(\d\d)(\d{0,5})/;
    subst = "($1) $2";
  }

  return r.replace(regex, subst);
};

const logout = () => {
  const channelPath = sessionStorage.getItem("channelPath") || "recarga";

  sessionStorage.clear();

  sessionStorage.setItem("channelPath", channelPath);
  sessionStorage.setItem("channel", channels[storage.getItem("channelPath")]);
};

const isFilledCard = cardNumber => {
  return cardNumber.replace(/\s/g, "").length >= 14;
};

const isObjectEmpty = obj => {
  if (obj === null || obj === undefined) return true;
  return obj.constructor === Object && Object.keys(obj).length === 0;
};

const compact = obj => {
  let objClone = { ...obj };
  Object.keys(objClone).forEach(k => {
    if (objClone[k] && objClone[k].constructor === Object) {
      objClone = { ...objClone, [k]: compact(objClone[k]) };
    } else if (objClone[k] == null) {
      delete objClone[k];
    }
  });
  return objClone;
};

const isDesktop = () => {
  const minDesktopMediaQuery = "(min-width: 768px)";
  return window.matchMedia(minDesktopMediaQuery).matches;
};

const is3DSDataOnlyOldCardsEnabled = msisdn => {
  const { REACT_APP_3DS_OLDCARDS_MSISDN_GRANT_LIST: msisdnGrant } = process.env;

  return msisdnGrant === "ALL" || msisdnGrant.split(",").includes(msisdn);
};

const orderCardsByConfirmed = cards =>
  cards.sort((card1, card2) => {
    if (card1.confirmed && !card2.confirmed) {
      return -1;
    }
    if (!card1.confirmed && card2.confirmed) {
      return 1;
    }
    return 0;
  });

const getDDD = msisdn => {
  return msisdn.slice(0, 2);
};

const dddValidate = (dddToValidate, ddds) => {
  const exceptValue = `-${dddToValidate}`;

  return (ddds.includes("ALL") || ddds.includes(dddToValidate)) && !ddds.includes(exceptValue);
};

const channelValidate = (channelToValidate, channelsList) => {
  const exceptValue = `-${channelToValidate}`;

  return (
    (channelsList.includes("ALL") || channelsList.includes(channelToValidate)) &&
    !channelsList.includes(exceptValue)
  );
};

const getNextRechargeValue = (rechargeValues, value, newValue) => {
  const rechargeValueObj = rechargeValues.find(rv => rv.value === newValue);

  return rechargeValueObj;
};

const getUpsellProps = (
  rechargeValue,
  ddd,
  rechargeValues,
  customerState,
  channel,
  segment,
  newValue,
  isPrezao,
  offerValue
) => {
  let upsell;
  const upsells = PROPS_UPSELL.filter(item => {
    return (
      item.conditions.values.includes(rechargeValue) &&
      !item.conditions.excludedSegments.includes(segment) &&
      item.conditions.customerStatus.includes(customerState.status) &&
      ((isPrezao &&
        item.conditions.isPrezao.includes(isPrezao) &&
        item.conditions.offerValue.includes(offerValue)) ||
        (!isPrezao && item.conditions.isPrezao.includes(isPrezao))) &&
      dddValidate(ddd, item.conditions.ddds) &&
      channelValidate(channel, item.conditions.includedChannels)
    );
  });

  if (upsells.length) {
    upsell = upsells.find(
      upsellItem =>
        upsellItem?.type === typeUpsell.PREZAO ||
        (upsellItem?.type === typeUpsell.NORMAL &&
          getNextRechargeValue(rechargeValues, rechargeValue, newValue)) ||
        upsellItem.type === typeUpsell.CONTROLE_CUSTOM_1 ||
        upsellItem.type === typeUpsell.CONTROLE_CUSTOM_2 ||
        (upsellItem?.type === typeUpsell.NORMAL_CUSTOM &&
          getNextRechargeValue(rechargeValues, rechargeValue, newValue))
    );
  }
  return upsell;
};

const getPixExpiration = () => {
  return Moment()
    .add(2, "hours")
    .format("DD/MM/YYYY [às] HH[h]mm");
};

const getDayDate = () => {
  return Moment().date();
};

const getScheduleDayDate = day => {
  return day > LIMIT_DATE_WILYKIT ? 1 : day;
};

const getNextScheduleCycle = (referenceDateParam, frequencyParam) => {
  let nextCycle = "";
  const nowMoment = Moment();
  const thisYear = nowMoment.year();
  const thisMonth = nowMoment.month() + 1;
  const referenceDateMoment = Moment(referenceDateParam);

  if ([frequencyEnum.weekly, frequencyEnum.biweekly].includes(frequencyParam?.toUpperCase())) {
    nextCycle = "INVALID";
    return nextCycle;
  }

  if (
    referenceDateMoment.date() > LIMIT_DATE_WILYKIT ||
    referenceDateMoment.date() <= nowMoment.date()
  ) {
    const scheduleDay = getScheduleDayDate(referenceDateMoment.date());
    const lastMonth = new Date(`${thisMonth}/${scheduleDay}/${thisYear}`);
    nextCycle = Moment(lastMonth).add(1, "M");
  } else {
    const lastMonth = new Date(`${thisMonth}/${referenceDateMoment.date()}/${thisYear}`);
    nextCycle = Moment(lastMonth);
  }
  return {
    date: Moment(nextCycle).format("DD/MM/YYYY"),
    text: `Próxima recarga ${Moment(nextCycle).format("DD/MM/YYYY")}`,
  };
};

const isClaroFlex = channel => {
  return [channels.claro_flex, channels.claro_flex_inad].includes(channel);
};

const isValidRegexCvv = e => {
  const re = /^[0-9\b]+$/;
  return e.target.value === "" || re.test(e.target.value);
};

const getChannel = () => {
  return sessionStorage.getItem("channel");
};

const nocreditChannels = [
  channels["no-credit"],
  channels.claro_nc_fb_autoflex,
  channels.claro_whatsapp_pay_lp,
];

const getTid = () => {
  return sessionStorage.getItem("tid");
};

const getSessionId = () => {
  return sessionStorage.getItem("sessionId");
};

const getOfferId = () => {
  return sessionStorage.getItem("offerId");
};

const getUtmContent = () => {
  return sessionStorage.getItem("utmContent");
};

const getUtmMedium = () => {
  return sessionStorage.getItem("utmMedium");
};

const getUtmSource = () => {
  return sessionStorage.getItem("utmSource");
};

const getUtmCampaign = () => {
  return sessionStorage.getItem("utmCampaign");
};

const clearTid = () => {
  return sessionStorage.removeItem("tid");
};

const clearSessionId = () => {
  return sessionStorage.removeItem("sessionId");
};

const clearOfferId = () => {
  return sessionStorage.removeItem("OfferId");
};

const clearUtmContent = () => {
  return sessionStorage.removeItem("utmContent");
};

const clearUtmMedium = () => {
  return sessionStorage.removeItem("utmMedium");
};

const clearUtmSource = () => {
  return sessionStorage.removeItem("utmSource");
};

const clearUtmCampaign = () => {
  return sessionStorage.removeItem("utmCampaign");
};

const getRouteForPaymentMethod = paymentMethod => {
  const routeMapper = paymentRoutes[paymentMethod] || (() => "confirmacao");
  return routeMapper();
};

const getTodayDate = () => {
  return Moment().format("YYYY-MM-DD");
};

const isMastercard = brand => {
  return brand === "MASTERCARD";
};

const isControlChannel = channel => {
  return [
    channels.claro_flex,
    channels.claro_flex_inad,
    channels.controle_facil,
    channels.controle_web,
  ].includes(channel);
};

const isMinhaClaro = channel => {
  return [
    channels.minhaclaro_app,
    channels.minhaclaro_web,
    channels.minhaclaro_app_nlog,
    channels.minhaclaro_web_nlog,
  ].includes(channel);
};

const getSegment = () => {
  return storage.getItem("segment");
};

const isPosControlSegment = segment => {
  return [segments.pos, segments.controle].includes(segment);
};

const isElegibleTagsChannel = channel => {
  return [
    channels.claro_nc_fb_autoflex,
    channels.claro_whatsapp_pay_lp,
    channels.claro_app_bot_web,
  ].includes(channel);
};

const shouldDisplayPixButton = channel => {
  return [channels.claro_nc_fb_autoflex, channels.claro_app_bot_web].includes(channel);
};

export {
  isObjectEmpty,
  getCardFlag,
  getCardFlagImage,
  getCardBrandImage,
  msisdnFormatter,
  cpfFormatter,
  dateOnlyFormatter,
  moneyFormatter2,
  installmentFormatter,
  categoryFormatter,
  priceFormatter,
  splitMoney,
  isValidMsisdn,
  generatePayload,
  normalizeGooglePay,
  inPeriod,
  isChrome,
  cvvMask,
  cpfMask,
  msisdnMask,
  logout,
  isFilledCard,
  compact,
  isDesktop,
  is3DSDataOnlyOldCardsEnabled,
  orderCardsByConfirmed,
  getUpsellProps,
  getPixExpiration,
  getNextScheduleCycle,
  isValidRegexCvv,
  isClaroFlex,
  getChannel,
  getRouteForPaymentMethod,
  getTodayDate,
  getDayDate,
  getDDD,
  dddValidate,
  channelValidate,
  filteredRecharges,
  getScheduleDayDate,
  isMastercard,
  isControlChannel,
  isMinhaClaro,
  getSegment,
  isPosControlSegment,
  isElegibleTagsChannel,
  getTid,
  getSessionId,
  getOfferId,
  getUtmContent,
  getUtmMedium,
  getUtmSource,
  getUtmCampaign,
  clearTid,
  clearSessionId,
  clearOfferId,
  clearUtmContent,
  clearUtmMedium,
  clearUtmSource,
  clearUtmCampaign,
  nocreditChannels,
  shouldDisplayPixButton,
};
