import { eventNames, paymentMethod } from "../enum/events";

const getConfirmationTagsPayload = (
  payload,
  tid,
  sessionId,
  offerId,
  utmContent,
  utmMedium,
  utmSource,
  utmCampaign
) => {
  let tags = {
    value: `${payload.rechargeValue.value / 100}`,
    currency: "BRL",
    inventory_type: "SIM_TOP_UP_WITH_DATA_BONUS",
    payment_method: paymentMethod[payload.paymentMethod.type],
    card_brand: payload.paymentMethod.data.brandName,
    utm_content: utmContent || "",
    utm_medium: utmMedium || "",
    utm_source: utmSource || "",
    utm_campaign: utmCampaign || "",
  };

  if (tid) {
    tags = { ...tags, transaction_id_meta: tid };
  }

  if (sessionId) {
    tags = { ...tags, session_id: sessionId };
  }

  if (offerId) {
    tags = { ...tags, offer_id: offerId };
  }

  return tags;
};

const mountEventPayload = (
  typeEvent,
  payload,
  tid,
  sessionId,
  offerId,
  utmContent,
  utmMedium,
  utmSource,
  utmCampaign
) => {
  let eventPayload = {};
  if (typeEvent === eventNames.confirmation) {
    eventPayload = getConfirmationTagsPayload(
      payload,
      tid,
      sessionId,
      offerId,
      utmContent,
      utmMedium,
      utmSource,
      utmCampaign
    );
  }
  return eventPayload;
};

export { mountEventPayload };
